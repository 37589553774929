<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">讲师列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="讲师姓名" class="searchboxItem ci-full">
              <span class="itemLabel">讲师姓名:</span>
              <el-input v-model="searchName" type="text" size="small" placeholder="请输入讲师姓名" clearable />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="searchIdcard" type="text" size="small" placeholder="请输入身份证号" clearable />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="searchPhone" type="text" size="small" placeholder="请输入手机号" clearable />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="add()">新增</el-button>
          </div>

        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :span-method="objectSpanMethod" :header-cell-style="tableHeader"
              :cell-style="cellbgc">
              <!-- <el-table-column label="序号" align="center" prop="index" /> -->
              <el-table-column label="讲师姓名" align="left" prop="teacherName" show-overflow-tooltip />
              <el-table-column label="身份证号" align="left" prop="teacherIdcard" min-width="140" show-overflow-tooltip />
              <el-table-column label="手机号" align="left" prop="teacherPhoneNumber" />
              <el-table-column label="资质类型" align="left" prop="qualificationTypeName" show-overflow-tooltip />
              <el-table-column label="职业证书编号" align="left" prop="certificateNo" show-overflow-tooltip />
              <el-table-column label="证书有效期/发证日期" align="left" min-width="140" show-overflow-tooltip>
                <template slot-scope="scope">
                  <template v-if="scope.row.qualificationIsForever == true">{{ scope.row.qualificationStartDate |
            momentDate }}</template>
                  <template v-else>{{ scope.row.qualificationStartDate | momentDate }} - {{
            scope.row.qualificationEndDate
            | momentDate }}</template>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="上传机构"
                align="center"
                prop="compName"
                show-overflow-tooltip
              />-->
              <el-table-column label="上传时间" align="left">
                <template slot-scope="scope">{{ scope.row.updateTime | momentDate }}</template>
              </el-table-column>
              <el-table-column label="审核状态" align="center">
                <template slot-scope="scope">
                  {{
            $setDictionary("AUDITSTATE", scope.row.auditState)
          }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 15px" size="mini"
                    @click="handleEdit(scope.row.teacherId)">编辑</el-button>
                  <el-button type="text" style="padding:0px 15px" size="mini"
                    @click="deleteData(scope.row)" :disabled="!scope.row.canDeleted">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "lecturerList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      searchName: "",
      searchIdcard: "",
      searchPhone: "",
      addlist: [], // 新增列表
      addobj: {
        name: 123
      },
      spanArr: []
    };
  },
  computed: {},
  watch: {
    searchName: function (val) {
      if (val == "") {
        this.$route.meta.state = false;
      }
    },
    // 缓存之后监听路由 refersh 是否刷新
    $route: function (route) {
      // if(route.name !='lecturer/list') return;
      if (route.params.refresh) {
        if (route.params.isAdd) {
          this.searchName = "";
          this.searchIdcard = "";
          this.searchPhone = "";
          this.getData();
        } else {
          this.getData();
        }
      } else {
        this.getData();
      }
    }
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        teacherName: this.searchName || "",
        teacherIdcard: this.searchIdcard || "",
        teacherPhoneNumber: this.searchPhone || ""
      };
      this.doFetch({
        url: "/biz/teacher/listPage",
        params,
        pageNum
      });
    },
    // 修改讲师
    handleEdit(id = "") {
      this.$router.push({
        path: "/web/addLecturer",
        query: { id, stu: "edit" }
      });
    },
    // 合并行  && row.row
    objectSpanMethod({ row, columnIndex }) {
      if (columnIndex < 3) {
        // 第一列
        return {
          rowspan: row.row,
          colspan: row.row ? 1 : 0
        };
      }
    },
    cellbgc({ rowIndex, columnIndex }) {
      if (columnIndex < 3 || columnIndex == 8 || columnIndex === 9) {
        return {
          backgroundColor: "transparent"
        };
      } else {
        if (rowIndex % 2 == 0) {
          return {
            backgroundColor: "rgb(242,247,253)"
          };
        }
      }
    },
    // 新增
    add() {
      this.$router.push({ path: "/web/addLecturer", query: { stu: "add" } });
    },
    // 删除
    deleteData(row) {
      this.$post("/biz/teacher/qualification/delete", { teacherQualificationId: row.teacherQualificationId })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err);
        });
      // this.$post("/biz/teacher/qualification/delete", { teacherId: row.teacherId })
      //   .then((res) => {
      //     this.$message.success("操作成功");
      //     this.getData(this.pageNum);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    // 处理数据方法，在混入里有该方法的调用
    handleListDataBack(data = []) {
      const teachers = {};
      data.forEach((item, index) => {
        if (!teachers[item.teacherId]) {
          teachers[item.teacherId] = [];
        }
        teachers[item.teacherId].push(index);
      });
      for (const key in teachers) {
        const element = teachers[key];
        element.forEach((item, index) => {
          if (index === 0) {
            data[item].row = element.length;
          } else {
            data[item].row = 0;
          }
        });
      }
      this.tableData = data;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例
      if (from.path == "/web/lecturerYwEdit" || from.path == "/web/lecturerYwEdit") {
        vm.getData(vm.pageNum);
      }
    });
  },
};
</script>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }

  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.zsBtn {
  margin-left: 20px;

  button {
    height: 40px;
  }
}

.certificate-box {
  >div:last-child {
    border: 0;
  }
}

.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
